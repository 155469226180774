export const years = ['107', '108', '109', '110', '111', '112', '113'];
export const ADyears = ['2018', '2019', '2020', '2021', '2022', '2023', '2024'];

type unitOptions = {
    value: string
    label: string
}
export const season: unitOptions[] = [
    { value: '1', label: 'Q1' },
    { value: '2', label: 'Q2' },
    { value: '3', label: 'Q3' },
    { value: '4', label: 'Q4' },
]
export const unit: unitOptions[] = [
    { value: 'y', label: '年' },
    { value: 's', label: '季' },
]

export const companyOptions = [
    '1101 台泥 taiwancement.com',
    '1102 亞泥 acc.com.tw',
    '1103 嘉泥 chcgroup.com.tw',
    '1104 環泥 ucctw.com',
    '1108 幸福 luckygrp.com.tw',
    '1109 信大 hsingta.com.tw',
    '1110 東泥 southeastcement.com.tw',
    '1201 味全 weichuan.com.tw',
    '1203 味王 vewong.com',
    '1210 大成 dachan.com',
    '1213 大飲 applesidra.com.tw',
    '1215 卜蜂 cptwn.com.tw',
    '1216 統一 uni-president.com.tw',
    '1217 愛之味 agv.com.tw',
    '1218 泰山 taisun.com.tw',
    '1219 福壽 fwusow.com.tw',
    '1220 台榮 tairoun.com.tw',
    '1225 福懋油 fopco.com.tw',
    '1227 佳格 sfworldwide.com',
    '1229 聯華 lhicholdings.com',
    '1231 聯華食 lianhwa.com.tw',
    '1232 大統益 ttet.com.tw',
    '1233 天仁 tenren.com.tw',
    '1234 黑松 heysong.com.tw',
    '1235 興泰 shintai-feed.com.tw',
    '1236 宏亞 hunya.com.tw',
    '1240 茂生農經 morn-sun.com.tw',
    '1256 鮮活果汁-KY myfreshjuice.com',
    '1259 安心 mos.com.tw',
    '1264 德麥 tehmag.com.tw',
    '1268 漢來美食 hilai-foods.com',
    '1301 台塑 fpc.com.tw',
    '1303 南亞 npc.com.tw',
    '1304 台聚 usife.com.tw',
    '1305 華夏 cgpc.com.tw',
    '1307 三芳 sanfang.com.tw',
    '1308 亞聚 apc.com.tw',
    '1309 台達化 ttc.com.tw',
    '1310 台苯 smct.com.tw',
    '1312 國喬 gppc.com.tw',
    '1313 聯成 upc.com.tw',
    '1314 中石化 cpdc.com.tw',
    '1315 達新 tahhsin.com.tw',
    '1316 上曜 sunyad.com.tw',
    '1319 東陽 tyg.com.tw',
    '1321 大洋 opc.com.tw',
    '1323 永裕 yonyu.com.tw',
    '1324 地球 globetape.com.tw',
    '1325 恆大 uk.com.tw',
    '1326 台化 fcfc.com.tw',
    '1336 台翰 thpt.com.tw',
    '1337 再生-KY asia-recycle.com',
    '1338 廣華-KY hirosawa.com.cn',
    '1339 昭輝 yccco.com.tw',
    '1340 勝悅-KY vnm.com.tw',
    '1341 富林-KY fulinvn.com',
    '1342 八貫 cathayconsolidated.com',
    '1402 遠東新 fenc.com',
    '1409 新纖 shinkong.com.tw',
    '1410 南染 nydf.com.tw',
    '1413 宏洲 hungchou.com.tw',
    '1414 東和 tungho.com.tw',
    '1416 廣豐 kfic.com.tw',
    '1417 嘉裕 carnival.com.tw',
    '1418 東華 tonghwa.com.tw',
    '1419 新紡 sktextile.com.tw',
    '1423 利華 reward.com.tw',
    '1432 大魯閣 taroko.com.tw',
    '1434 福懋 ftc.com.tw',
    '1435 中福 chungfutex.com.tw',
    '1436 華友聯 huakai.com.tw',
    '1437 勤益控 gtm.com.tw',
    '1438 三地開發 sandirealestate.com.tw',
    '1439 雋揚 ascentglobal.com.tw',
    '1440 南紡 tainanspin.com.tw',
    '1441 大東 tahtong.com.tw',
    '1442 名軒 advancetek.com.tw',
    '1443 立益物流 lily.com.tw',
    '1444 力麗 lealeagroup.com.tw',
    '1445 大宇 utc.tw',
    '1446 宏和 hongho.com.tw',
    '1447 力鵬 lipeng.com.tw',
    '1449 佳和 chgtex.com',
    '1451 年興 nhjeans.com',
    '1452 宏益 hongyilon.com.tw',
    '1453 大將 tajiang.com.tw',
    '1454 台富 ttfco.com',
    '1455 集盛 zigsheng.com',
    '1456 怡華 ihwa.com.tw',
    '1457 宜進 yijinn.com.tw',
    '1459 聯發 lanfa.com.tw',
    '1460 宏遠 everest.com.tw',
    '1463 強盛 csgroup.com.tw',
    '1464 得力 delicacy.com.tw',
    '1465 偉全 wisher.com.tw',
    '1466 聚隆 acelon.com.tw',
    '1467 南緯 texray.com',
    '1468 昶和 chang-ho.com.tw',
    '1470 大統新創 evertex.tw',
    '1471 首利 soly-tech.com',
    '1472 三洋實業 triocean.com.tw',
    '1473 台南 eng.tai-nan.com',
    '1474 弘裕 textile-hy.com.tw',
    '1475 業旺 big-sunshine.com.tw',
    '1476 儒鴻 eclat.com.tw',
    '1477 聚陽 makalot.com.tw',
    '1503 士電 seec.com.tw',
    '1504 東元 teco.com.tw',
    '1506 正道 rightway.com.tw',
    '1512 瑞利 juili.com.tw',
    '1513 中興電 chem.com.tw',
    '1514 亞力 allis.com.tw',
    '1515 力山 rexon.net',
    '1516 川飛 falconpower.tw',
    '1517 利奇 leechi.com.tw',
    '1519 華城 fortune.com.tw',
    '1521 大億 tayih-ind.com.tw',
    '1522 堤維西 tyc.com.tw',
    '1524 耿鼎 gordon.com.tw',
    '1525 江申 kian-shen.com',
    '1526 日馳 sunrace.com',
    '1527 鑽全 basso.com.tw',
    '1528 恩德 anderson.com.tw',
    '1529 樂事綠能 luxe.com.tw',
    '1530 亞崴 awea.com',
    '1531 高林股 siruba.com',
    '1532 勤美 cmp.com.tw',
    '1533 車王電 more.com.tw',
    '1535 中宇 ecotek.com.tw',
    '1536 和大 hota.com.tw',
    '1537 廣隆 klb.com.tw',
    '1538 正峰 jennfeng.com',
    '1539 巨庭 geetech.com.tw',
    '1540 喬福 johnford.com.tw',
    '1541 錩泰 toty.com.tw',
    '1558 伸興 zenghsing.com.tw',
    '1560 中砂 kinik.com.tw',
    '1565 精華 stshine.com.tw',
    '1568 倉佑 tsangyow.com.tw',
    '1569 濱川 hamagawa.com.tw',
    '1570 力肯 depoan.com',
    '1580 新麥 sinmag.com.tw',
    '1582 信錦 syncmold.com.tw',
    '1583 程泰 goodwaycnc.com',
    '1584 精剛 s-tech.com.tw',
    '1586 和勤 cftc.tw',
    '1587 吉茂 cryomaxcooling.com',
    '1589 永冠-KY ygget.com',
    '1590 亞德客-KY airtac.com',
    '1591 駿吉-KY inmax-fasteners.com',
    '1593 祺驊 chihua.com.tw',
    '1595 川寶 cbtech.com.tw',
    '1597 直得 chieftek.com',
    '1598 岱宇 dyaco.com',
    '1599 宏佳騰 aeonmotor.com',
    '1603 華電 cwcowin.com.tw',
    '1604 聲寶 sampo.com.tw',
    '1605 華新 walsin.com',
    '1608 華榮 hegroup.com.tw',
    '1609 大亞 taya.com.tw',
    '1611 中電 chinaelectric.com.tw',
    '1612 宏泰 hong-tai.com.tw',
    '1614 三洋電 sanyo.com.tw',
    '1615 大山 dahsan.com.tw',
    '1616 億泰 evertop.com',
    '1617 榮星 jswire.com.tw',
    '1618 合機 hold-key.com.tw',
    '1626 艾美特-KY airmate-china.com',
    '1701 中化 ccpc.com.tw',
    '1702 南僑 namchow.com.tw',
    '1707 葡萄王 grapeking.com.tw',
    '1708 東鹼 sesoda.com.tw',
    '1709 和益 fucc.com.tw',
    '1710 東聯 oucc.com.tw',
    '1711 永光 ecic.com',
    '1712 興農 sinon.com.tw',
    '1713 國化 ccwi.com.tw',
    '1714 和桐 htgroup.com.tw',
    '1717 長興 eternal-group.com',
    '1718 中纖 cmfc.com.tw',
    '1720 生達 standard.com.tw',
    '1721 三晃 sunko.com.tw',
    '1722 台肥 taifer.com.tw',
    '1723 中碳 cscc.com.tw',
    '1725 元禎 yuanjen.com',
    '1726 永記 rainbowpaint.com.tw',
    '1727 中華化 chciw.com.tw',
    '1730 花仙子 farcent.com.tw',
    '1731 美吾華 maywufa.com.tw',
    '1732 毛寶 maobao.com.tw',
    '1733 五鼎 apexbio.com.tw',
    '1734 杏輝 sinphar.com',
    '1735 日勝化 twemc.com',
    '1736 喬山 johnsonfitness.com',
    '1737 臺鹽 tybio.com.tw',
    '1742 台蠟 wax.com.tw',
    '1752 南光 nangkuang.com.tw',
    '1760 寶齡富錦 pbf.com.tw',
    '1762 中化生 ccsb.com.tw',
    '1773 勝一 shinychem.com.tw',
    '1776 展宇 headway.com.tw',
    '1777 生泰 syn-tech.com.tw',
    '1781 合世 healthandlife.com.tw',
    '1783 和康生 mbi.com.tw',
    '1784 訊聯 bionetcorp.com',
    '1785 光洋科 solartech.com.tw',
    '1786 科妍 scivision.com.tw',
    '1788 杏昌 hiclearance.com.tw',
    '1789 神隆 scinopharm.com.tw',
    '1795 美時 lotuspharm.com.tw',
    '1796 金穎生技 geneferm.com',
    '1799 易威 easywellbio.com',
    '1802 台玻 taiwanglass.com',
    '1805 寶徠 blgroup.com.tw',
    '1806 冠軍 group.champion.com.tw',
    '1808 潤隆 runlong.com.tw',
    '1809 中釉 china-glaze.com.tw',
    '1810 和成 hcg.com.tw',
    '1813 寶利徠 gialens.com.tw',
    '1815 富喬 ffg.com.tw',
    '1817 凱撒衛 caesar.com.tw',
    '1903 士紙 shihlin.com.tw',
    '1904 正隆 clc.com.tw',
    '1905 華紙 chp.com.tw',
    '1906 寶隆 baolongintl.com',
    '1907 永豐餘 yfy.com',
    '1909 榮成 longchenpaper.com',
    '2002 中鋼 csc.com.tw',
    '2006 東和鋼鐵 tunghosteel.com',
    '2007 燁興 yheco.com.tw',
    '2008 高興昌 khc.com.tw',
    '2009 第一銅 fcht.com.tw',
    '2010 春源 cysco.com.tw',
    '2012 春雨 chunyu.com.tw',
    '2013 中鋼構 cssc.com.tw',
    '2014 中鴻 chsteel.com.tw',
    '2015 豐興 fenghsin.com.tw',
    '2017 官田鋼 quintain.com.tw',
    '2020 美亞 mayer.com.tw',
    '2022 聚亨 tycons.com.tw',
    '2023 燁輝 yiehphui.com.tw',
    '2024 志聯 chihlien.com.tw',
    '2025 千興 csssc.com.tw',
    '2027 大成鋼 tachen.com.tw',
    '2028 威致 weichih.com.tw',
    '2029 盛餘 shengyusteel.com',
    '2030 彰源 froch.com',
    '2031 新光鋼 hkssteel.com.tw',
    '2032 新鋼 sinkang.com.tw',
    '2033 佳大 ctworld.com.tw',
    '2034 允強 ycinox.com',
    '2035 唐榮公司 tangeng.com.tw',
    '2038 海光 haikwang.com.tw',
    '2049 上銀 hiwin.tw',
    '2059 川湖 kingslide.com',
    '2061 風青 fcm.com.tw',
    '2062 橋椿 sunspring.com.tw',
    '2063 世鎧 shehkai.com.tw',
    '2064 晉椿 camelliametal.com',
    '2065 世豐 shehfung.com',
    '2066 世德 sumeeko.com',
    '2067 嘉鋼 cysteel.com.tw',
    '2069 運錩 yuenchang.com.tw',
    '2070 精湛 ccm3s.com',
    '2073 雄順 ssmc.com.tw',
    '2101 南港 nankang-tyre.com',
    '2102 泰豐 federalcorporation.com',
    '2103 台橡 tsrc.com.tw',
    '2104 國際中橡 csrcgroup.com',
    '2105 正新 cst.com.tw',
    '2106 建大 kendatire.com',
    '2107 厚生 frg.com.tw',
    '2108 南帝 nantex.com.tw',
    '2109 華豐 duro.com.tw',
    '2114 鑫永銓 hyc-king.com',
    '2115 六暉-KY luhai.com.tw',
    '2201 裕隆 yulon-motor.com.tw',
    '2204 中華 china-motor.com.tw',
    '2206 三陽工業 sanyang.com.tw',
    '2207 和泰車 hotaimotor.com.tw',
    '2208 台船 csbcnet.com.tw',
    '2211 長榮鋼 evergreennet.com',
    '2221 大甲 yhmco.com.tw',
    '2227 裕日車 nissan.com.tw',
    '2228 劍麟 ironforce.com.tw',
    '2230 泰茂 ctico.com.tw',
    '2231 為升 cubelec.com',
    '2233 宇隆 turvo.com.tw',
    '2235 謚源 tiy-motor.com',
    '2236 百達-KY patec-intl.com',
    '2239 英利-KY engley.com.tw',
    '2241 艾姆勒 amulaire.com',
    '2243 宏旭-KY horngshiue.com',
    '2247 汎德永業 pgum.com.tw',
    '2250 IKKA-KY ikka.com.tw',
    '2254 巨鎧精密-創 coplus.com.tw',
    '2258 鴻華先進-創 foxtronev.com',
    '2301 光寶科 liteon.com',
    '2302 麗正 rectron.com.tw',
    '2303 聯電 umc.com',
    '2305 全友 microtek.com.tw',
    '2308 台達電 deltaww.com',
    '2312 金寶 kinpo.com.tw',
    '2313 華通 compeq.com.tw',
    '2314 台揚 mtigroup.com',
    '2316 楠梓電 wus.com.tw',
    '2317 鴻海 foxconn.com',
    '2321 東訊 tecom.com.tw',
    '2323 中環 cmcnet.com.tw',
    '2324 仁寶 compal.com',
    '2327 國巨 yageo.com',
    '2328 廣宇 panintl.com',
    '2329 華泰 ose.com.tw',
    '2330 台積電 tsmc.com',
    '2331 精英 ecs.com.tw',
    '2332 友訊 company.dlink.com',
    '2337 旺宏 macronix.com',
    '2338 光罩 tmcnet.com.tw',
    '2340 台亞 tascsemi.com',
    '2342 茂矽 mosel.com.tw',
    '2344 華邦電 winbond.com',
    '2345 智邦 accton.com',
    '2347 聯強 synnex-grp.com',
    '2348 海悅 hiyes-group.com.tw',
    '2349 錸德 ritek.com.tw',
    '2351 順德 sdi.com.tw',
    '2352 佳世達 qisda.com.tw',
    '2353 宏碁 acer.com',
    '2354 鴻準 foxconntech.com.tw',
    '2355 敬鵬 chinpoon.com',
    '2356 英業達 inventec.com',
    '2357 華碩 asus.com.tw',
    '2358 廷鑫 tingsin.com.tw',
    '2359 所羅門 solomon.com.tw',
    '2360 致茂 chromaate.com',
    '2362 藍天 clevo.com.tw',
    '2363 矽統 sis.com',
    '2364 倫飛 twinhead.com.tw',
    '2365 昆盈 geniusnet.com.tw',
    '2367 燿華 pcbut.com.tw',
    '2368 金像電 gce.com.tw',
    '2369 菱生 lingsen.com.tw',
    '2371 大同 tatung.com.tw',
    '2373 震旦行 aurora.com.tw',
    '2374 佳能 abilitycorp.com.tw',
    '2375 凱美 jamicon.teapo.com',
    '2376 技嘉 gigabyte.com',
    '2377 微星 tw.msi.com',
    '2379 瑞昱 realtek.com',
    '2380 虹光 avision.com.tw',
    '2382 廣達 quantatw.com',
    '2383 台光電 emctw.com',
    '2385 群光 chicony.com',
    '2387 精元 sunrex.com.tw',
    '2388 威盛 via.com.tw',
    '2390 云辰 everspring.com',
    '2392 正崴 foxlink.com',
    '2393 億光 everlight.com',
    '2395 研華 advantech.com',
    '2397 友通 dfi.com',
    '2399 映泰 biostar.com.tw',
    '2401 凌陽 sunplus.com',
    '2402 毅嘉 ichia.com',
    '2404 漢唐 uisco.com.tw',
    '2405 輔信 shuttle.com',
    '2406 國碩 gigastorage.com.tw',
    '2408 南亞科 nanya.com',
    '2409 友達 auo.com',
    '2412 中華電 cht.com.tw',
    '2413 環科 umec.com.tw',
    '2414 精技 unitech.com.tw',
    '2415 錩新 cxtechnology.com',
    '2417 圓剛 avermedia.com',
    '2419 仲琦 hitrontech.com',
    '2420 新巨 zippy.com.tw',
    '2421 建準 sunon.com',
    '2423 固緯 gwinstek.com',
    '2424 隴華 lhsat.com',
    '2425 承啟 chaintech.com.tw',
    '2426 鼎元 tyntek.com.tw',
    '2427 三商電 mds.com.tw',
    '2428 興勤 thinking.com.tw',
    '2429 銘旺科 abonmax.com.tw',
    '2430 燦坤 tk3c.com.tw',
    '2431 聯昌 lienchang.com.tw',
    '2432 倚天酷碁-創 www.acergadget.com',
    '2433 互盛電 eosasc.com.tw',
    '2434 統懋 mospec.com.tw',
    '2436 偉詮電 weltrend.com.tw',
    '2438 翔耀 enlightcorp.com.tw',
    '2439 美律 merry.com.tw',
    '2440 太空梭 spaces.com.tw',
    '2441 超豐 greatek.com.tw',
    '2442 新美齊 jean.com.tw',
    '2443 昶虹 leaddata.com.tw',
    '2444 兆勁 abocom.com.tw',
    '2449 京元電子 kyec.com.tw',
    '2450 神腦 senao.com.tw',
    '2451 創見 transcend-info.com',
    '2453 凌群 syscom.com.tw',
    '2454 聯發科 mediatek.com',
    '2455 全新 vpec.com.tw',
    '2457 飛宏 phihong.com.tw',
    '2458 義隆 emc.com.tw',
    '2459 敦吉 audix.com',
    '2460 建通 gem.com.tw',
    '2461 光群雷 klasergroup.com',
    '2462 良得電 linetek.com.tw',
    '2464 盟立 mirle.com.tw',
    '2465 麗臺 leadtek.com.tw',
    '2466 冠西電 cosmo-inc.com',
    '2467 志聖 csun.com.tw',
    '2468 華經 fis.com.tw',
    '2471 資通 ares.com.tw',
    '2472 立隆電 lelon.com.tw',
    '2474 可成 catcher-group.com',
    '2476 鉅祥 gs.com.tw',
    '2477 美隆電 meiloon.com.tw',
    '2478 大毅 tai.com.tw',
    '2480 敦陽科 sti.com.tw',
    '2481 強茂 panjit.com.tw',
    '2482 連宇 uicpayworld.com',
    '2483 百容 ece.com.tw',
    '2484 希華 siward.com',
    '2485 兆赫 zinwell.com.tw',
    '2486 一詮 i-chiun.com.tw',
    '2488 漢平 hanpin.com.tw',
    '2489 瑞軒 amtran.com.tw',
    '2491 吉祥全 focl.com.tw',
    '2492 華新科 passivecomponent.com',
    '2493 揚博 ampoc.com.tw',
    '2495 普安 infortrend.com',
    '2496 卓越 chenliedu2496.com',
    '2497 怡利電 e-lead.com.tw',
    '2498 宏達電 htc.com',
    '2501 國建 cathay-red.com.tw',
    '2504 國產 gdc.com.tw',
    '2505 國揚 kycc.com.tw',
    '2506 太設 pacific-group.com.tw',
    '2509 全坤建 gtg.com.tw',
    '2511 太子 prince.com.tw',
    '2514 龍邦 longbon.com.tw',
    '2515 中工 bes.com.tw',
    '2516 新建 newasia.com.tw',
    '2520 冠德 kindom.com.tw',
    '2524 京城 kingtown.com.tw',
    '2527 宏璟 asehcc.com.tw',
    '2528 皇普 crowell.com.tw',
    '2530 華建 delpha.com.tw',
    '2534 宏盛 hsc.com.tw',
    '2535 達欣工 dacin.com.tw',
    '2536 宏普 hong-pu.com.tw',
    '2537 聯上發 5v.com.tw',
    '2538 基泰 keetai.com.tw',
    '2539 櫻花建 sakurad.com.tw',
    '2540 愛山林 isanlin.com',
    '2542 興富發 highwealth.com.tw',
    '2543 皇昌 hcgc.com.tw',
    '2545 皇翔 hhe.com.tw',
    '2546 根基 kedge.com.tw',
    '2547 日勝生 radium.com.tw',
    '2548 華固 huaku.com.tw',
    '2596 綠意 realycorp.com.tw',
    '2597 潤弘 rtc.com.tw',
    '2601 益航 firsteam.com.tw',
    '2603 長榮 evergreen-marine.com',
    '2605 新興 snc.com.tw',
    '2606 裕民 uming.com.tw',
    '2607 榮運 evergreen-eitc.com.tw',
    '2608 嘉里大榮 kerrytj.com',
    '2609 陽明 yangming.com',
    '2610 華航 china-airlines.com',
    '2611 志信 tsgroup.com.tw',
    '2612 中航 cmt.tw',
    '2613 中櫃 cctcorp.com.tw',
    '2614 東森 emic.com.tw',
    '2615 萬海 tw.wanhai.com',
    '2616 山隆 slc.com.tw',
    '2617 台航 taiwanline.com.tw',
    '2618 長榮航 evaair.com',
    '2630 亞航 airasia.com.tw',
    '2633 台灣高鐵 thsrc.com.tw',
    '2634 漢翔 aidc.com.tw',
    '2636 台驊投控 t3ex-group.com',
    '2637 慧洋-KY wisdomlines.com.tw',
    '2640 大車隊 taiwantaxi.com.tw',
    '2641 正德 franbo.com.tw',
    '2642 宅配通 e-can.com.tw',
    '2643 捷迅 soonest.com',
    '2645 長榮航太 egat.com.tw',
    '2701 萬企 wanhwa.com.tw',
    '2702 華園 hotelhg.com.tw',
    '2704 國賓 ambassador-hotels.com',
    '2705 六福 leofoo.com.tw',
    '2706 第一店 twfirsthotel.com.tw',
    '2707 晶華 silkshotelgroup.com',
    '2712 遠雄來 farglory-hotel.com.tw',
    '2718 晶悅 pleasanthotels.com.tw',
    '2719 燦星旅 startravel.com.tw',
    '2722 夏都 chateau-hotels.com.tw',
    '2723 美食-KY 85cafe.com',
    '2724 藝舍-KY fxhotelsgroup.com',
    '2726 雅茗-KY yummy-town.tw',
    '2727 王品 wowprime.com',
    '2729 瓦城 ttfb.com',
    '2731 雄獅 liontravel.com',
    '2732 六角 lakaffagroup.com',
    '2734 易飛網 ezfly.com',
    '2736 富野 hoyaresort.com.tw',
    '2739 寒舍 mhh-group.com',
    '2740 天蔥 mr-onion.com',
    '2743 山富 travel4u.com.tw',
    '2745 五福 lifetour.com.tw',
    '2748 雲品 fdc-i.com',
    '2752 豆府 tofuglobal.com',
    '2753 八方雲集 8way.com.tw',
    '2754 亞洲藏壽司 investor.kurasushi.tw',
    '2755 揚秦 youngqin.com.tw',
    '2756 聯發國際 1992lianfa.com',
    '2762 世界健身-KY investor.worldgymtaiwan.com',
    '2801 彰銀 bankchb.com',
    '2809 京城銀 ktb.com.tw',
    '2812 台中銀 tcbbank.com.tw',
    '2816 旺旺保 wwunion.com',
    '2820 華票 cbf.com.tw',
    '2832 台產 tfmi.com.tw',
    '2834 臺企銀 tbb.com.tw',
    '2836 高雄銀 bok.com.tw',
    '2838 聯邦銀 ubot.com.tw',
    '2845 遠東銀 feib.com.tw',
    '2849 安泰銀 entiebank.com.tw',
    '2850 新產 skinsurance.com.tw',
    '2851 中再保 centralre.com',
    '2852 第一保 firstins.com.tw',
    '2855 統一證 pscnet.com.tw',
    '2867 三商壽 mli.com.tw',
    '2880 華南金 hnfhc.com.tw',
    '2881 富邦金 fubon.com',
    '2882 國泰金 cathayholdings.com',
    '2883 開發金 cdfholding.com',
    '2884 玉山金 esunfhc.com.tw',
    '2885 元大金 yuanta.com',
    '2886 兆豐金 megaholdings.com.tw',
    '2887 台新金 taishinholdings.com.tw',
    '2888 新光金 skfh.com.tw',
    '2889 國票金 ibf.com.tw',
    '2890 永豐金 sinopac.com',
    '2891 中信金 ctbcholding.com',
    '2892 第一金 firstholding.com.tw',
    '2897 王道銀行 o-bank.com',
    '2901 欣欣 shinshinltd.com.tw',
    '2903 遠百 feds.com.tw',
    '2904 匯僑 pocs.com.tw',
    '2905 三商 mercuries.com.tw',
    '2906 高林 collins.com.tw',
    '2908 特力 testritegroup.com',
    '2910 統領 tonlin.com.tw',
    '2911 麗嬰房 enphants.com',
    '2912 統一超 7-11.com.tw',
    '2913 農林 ttch.com.tw',
    '2915 潤泰全 ruentex.com.tw',
    '2916 滿心 munsin.com.tw',
    '2923 鼎固-KY sinohorizon.tw',
    '2924 宏太-KY ggalactica.com',
    '2926 誠品生活 eslitespectrum.com.tw',
    '2929 淘帝-KY topbi.com.tw',
    '2937 集雅社 gseven.com.tw',
    '2939 凱羿-KY kayeetv.com',
    '2941 米斯特 life8.com.tw',
    '2945 三商家購 simplemartretail.com',
    '2947 振宇五金 ald.com.tw',
    '2948 寶陞 bausen.com',
    '2949 欣新網 hhgalaxy.com.tw',
    '3002 歐格 ahoku.com.tw',
    '3003 健和興 ksterminals.com.tw',
    '3004 豐達科 nafco.com.tw',
    '3005 神基 getacgroup.com',
    '3006 晶豪科 esmt.com.tw',
    '3008 大立光 largan.com.tw',
    '3010 華立 wahlee.com',
    '3011 今皓 jh.com.tw',
    '3013 晟銘電 uneec.com',
    '3014 聯陽 ite.com.tw',
    '3015 全漢 fsp-group.com',
    '3016 嘉晶 epi.episil.com',
    '3017 奇鋐 avc.co',
    '3018 隆銘綠能 lmgte.com.tw',
    '3019 亞光 asia-optical.com',
    '3021 鴻名 welltend.com.tw',
    '3022 威強電 ieiworld.com',
    '3023 信邦 sinbon.com',
    '3024 憶聲 action.com.tw',
    '3025 星通 looptelecom.com',
    '3026 禾伸堂 holystone.com.tw',
    '3027 盛達 billion.com',
    '3028 增你強 zenitron.com.tw',
    '3029 零壹 zerone.com.tw',
    '3030 德律 tri.com.tw',
    '3031 佰鴻 brtled.com',
    '3032 偉訓 hec-group.com.tw',
    '3033 威健 weikeng.com.tw',
    '3034 聯詠 novatek.com.tw',
    '3035 智原 faraday-tech.com',
    '3036 文曄 wtmec.com',
    '3037 欣興 unimicron.com',
    '3038 全台 edtc.com',
    '3040 遠見 gv.com.tw',
    '3041 揚智 alitech.com',
    '3042 晶技 txccorp.com',
    '3043 科風 upspowercom.com',
    '3044 健鼎 tripod-tech.com',
    '3045 台灣大 taiwanmobile.com',
    '3046 建碁 aopen.com',
    '3047 訊舟 edimax.com.tw',
    '3048 益登 edomtech.com',
    '3049 精金 hannstouch.com',
    '3050 鈺德 utechmedia.com.tw',
    '3051 力特 optimax.com.tw',
    '3052 夆典 apexgrp.com.tw',
    '3054 立萬利 liwanli.com.tw',
    '3055 蔚華科 spirox.com.tw',
    '3056 富華新 zte.zongtai.com.tw',
    '3057 喬鼎 promise.com',
    '3058 立德 lei.com.tw',
    '3059 華晶科 altek.com.tw',
    '3060 銘異 minaik.com.tw',
    '3062 建漢 cybertan.com.tw',
    '3064 泰偉 astrocorp.com.tw',
    '3066 李洲 oasistek.com',
    '3067 全域 twphonic.com',
    '3071 協禧 adda.com.tw',
    '3073 天方能源 tpenergy.com.tw',
    '3078 僑威 cwt.com.tw',
    '3081 聯亞 lmoc.com.tw',
    '3083 網龍 chinesegamer.net',
    '3085 新零售 newretail.com.tw',
    '3086 華義 wayi.net',
    '3088 艾訊 axiomtek.com.tw',
    '3090 日電貿 ndb.com.tw',
    '3092 鴻碩 hotron-ind.com',
    '3093 港建* tkk.com.tw',
    '3094 聯傑 davicom.com.tw',
    '3095 及成 chicheng.com.tw',
    '3105 穩懋 winfoundry.com',
    '3114 好德 howteh.com.tw',
    '3115 富榮綱 tscl.com.tw',
    '3118 進階 level.com.tw',
    '3122 笙泉 megawin.com.tw',
    '3128 昇銳 hisharp.com',
    '3130 一零四 corp.104.com.tw',
    '3131 弘塑 gptc.com.tw',
    '3138 耀登 auden.com.tw',
    '3141 晶宏 ultrachip.com',
    '3147 大綜 jetwell.com.tw',
    '3149 正達 gtoc.com.tw',
    '3152 璟德 acxc.com.tw',
    '3162 精確 al-alloy.com.tw',
    '3163 波若威 browave.com',
    '3164 景岳 genmont.com.tw',
    '3167 大量 taliang.com',
    '3168 眾福科 dataimage.com.tw',
    '3169 亞信 asix.com.tw',
    '3171 新洲 xinchio.com',
    '3176 基亞 medigen.com.tw',
    '3178 公準 gongin.com.tw',
    '3188 鑫龍騰 gltd.com.tw',
    '3189 景碩 kinsus.com.tw',
    '3191 雲科 holdjinn.com.tw',
    '3202 樺晟 high-tek.com.tw',
    '3205 佰研 naturalbiokey.com',
    '3206 志豐 kingstate.com.tw',
    '3207 耀勝 top-nation.com',
    '3209 全科 alltek.com',
    '3211 順達 dynapack.com.tw',
    '3213 茂訊 crete.com.tw',
    '3217 優群 argosy.com.tw',
    '3218 大學光 uvb.com.tw',
    '3219 倚強科 aether-tek.com',
    '3221 台嘉碩 taisaw.com',
    '3224 三顧 metatech.com.tw',
    '3226 至寶電 topower.com.tw',
    '3227 原相 pixart.com',
    '3228 金麗科 rdc.com.tw',
    '3229 晟鈦 cheer-time.com.tw',
    '3230 錦明 jmg.com.tw',
    '3231 緯創 wistron.com',
    '3232 昱捷 sentronic.com.tw',
    '3234 光環 truelight.com.tw',
    '3236 千如 atec-group.com',
    '3252 海灣 haiwan.tw',
    '3257 虹冠電 championmicro.com.tw',
    '3259 鑫創 3system.com.tw',
    '3260 威剛 adata.com',
    '3264 欣銓 ardentec.com',
    '3265 台星科 winstek.com.tw',
    '3266 昇陽 sunty.com.tw',
    '3268 海德威 higherway.com.tw',
    '3272 東碩 goodway.com.tw',
    '3276 宇環 tht-pcb.com.tw',
    '3284 太普高 ctptop.com.tw',
    '3285 微端 microtips.com.tw',
    '3287 廣寰科 kworld.com.tw',
    '3288 點晶 siti.com.tw',
    '3289 宜特 istgroup.com',
    '3290 東浦 donpon.com',
    '3293 鈊象 igs.com.tw',
    '3294 英濟 megaforce.com.tw',
    '3296 勝德 power-tech.com.tw',
    '3297 杭特 hunt.com.tw',
    '3303 岱稜 univacco.com.tw',
    '3305 昇貿 shenmao.com',
    '3306 鼎天 tw.royaltek.com',
    '3308 聯德 bestec.com.tw',
    '3310 佳穎 chialin.com.tw',
    '3311 閎暉 silitech.com',
    '3312 弘憶股 gmitec.com',
    '3313 斐成 fce.com.tw',
    '3317 尼克森 niko-sem.com',
    '3321 同泰 uniflex.com.tw',
    '3322 建舜電 jem.com.tw',
    '3323 加百裕 celxpert.com.tw',
    '3324 雙鴻 auras.com.tw',
    '3325 旭品 casing.com.tw',
    '3332 幸康 cincon.com.tw',
    '3338 泰碩 taisol.com',
    '3339 泰谷 tekcore.com.tw',
    '3346 麗清 lastertech.com',
    '3354 律勝 microcosm.com.tw',
    '3356 奇偶 geovision.com.tw',
    '3357 臺慶科 tai-tech.com.tw',
    '3360 尚立 sunnic.com',
    '3362 先進光 aoet.com.tw',
    '3363 上詮 foci.com.tw',
    '3372 典範 ticp.com.tw',
    '3373 熱映 radiantek.com.tw',
    '3374 精材 xintec.com.tw',
    '3376 新日興 szs-group.com',
    '3379 彬台 taibeco.com',
    '3380 明泰 alphanetworks.com',
    '3388 崇越電 topcocorp.com',
    '3390 旭軟 sunflex.com.tw',
    '3402 漢科 wholetech.com.tw',
    '3406 玉晶光 gseo.com',
    '3413 京鼎 foxsemicon.com.tw',
    '3416 融程電 winmate.com.tw',
    '3419 譁裕 whayu.com',
    '3426 台興 tai-shing.com.tw',
    '3430 奇鈦科 chitec.com',
    '3432 台端 taitwun.com.tw',
    '3434 哲固 acula.com',
    '3437 榮創 aot.com.tw',
    '3438 類比科 aat-ic.com',
    '3441 聯一光電 t-unique.com',
    '3443 創意 guc-asic.com',
    '3444 利機 niching.com.tw',
    '3447 展達 xavi.com.tw',
    '3450 聯鈞 elaser.com.tw',
    '3454 晶睿 vivotek.com',
    '3455 由田 utechzone.com.tw',
    '3465 進泰電子 naeaudio.com',
    '3466 德晉 skardin.com',
    '3479 安勤 avalue.com',
    '3481 群創 innolux.com',
    '3483 力致 forcecon.com',
    '3484 崧騰 solteam.com.tw',
    '3489 森寶 sunbrothers.com.tw',
    '3490 單井 singlewell.com.tw',
    '3491 昇達科 umt-tw.com',
    '3492 長盛 actt.co',
    '3494 誠研 hiti.com',
    '3498 陽程 usuntek.com.tw',
    '3499 環天科 globalsat.com.tw',
    '3501 維熹 wellshin.com.tw',
    '3504 揚明光 youngoptics.com',
    '3508 位速 waystech.net',
    '3511 矽瑪 simulatechnology.com',
    '3512 皇龍 hldc.com.tw',
    '3515 華擎 asrock.com.tw',
    '3516 亞帝歐 adotek.com.tw',
    '3518 柏騰 pttech.com.tw',
    '3520 華盈 jve-tech.com',
    '3521 鴻翊 datavan.com.tw',
    '3522 御頂 toplusglobal.com',
    '3523 迎輝 efun.com.tw',
    '3526 凡甲 otop.com.tw',
    '3527 聚積 mblock.com.tw',
    '3528 安馳 macnica.com',
    '3529 力旺 ememory.com.tw',
    '3530 晶相光 soinc.com.tw',
    '3531 先益 shianyih.com',
    '3532 台勝科 fstech.com.tw',
    '3533 嘉澤 lotes.cc',
    '3535 晶彩科 favite.com',
    '3537 堡達 podak.com.tw',
    '3540 曜越 thermaltake.com',
    '3541 西柏 cypress.com.tw',
    '3543 州巧 jochu.com',
    '3545 敦泰 focaltech-electronics.com',
    '3546 宇峻 uj.com.tw',
    '3548 兆利 jarlly.com',
    '3550 聯穎 copartner.com.tw',
    '3551 世禾 sht.com.tw',
    '3552 同致 tungthih.com.tw',
    '3555 博士旺 iroc.com.tw',
    '3556 禾瑞亞 eeti.com',
    '3557 嘉威 jiaweils.com',
    '3558 神準 senaonetworks.com',
    '3563 牧德 machvision.com.tw',
    '3564 其陽 aewin.com',
    '3567 逸昌 etrendtech.tw',
    '3570 大塚 oitc.com.tw',
    '3576 聯合再生 urecorp.com',
    '3577 泓格 icpdas.com',
    '3580 友威科 uvat.com',
    '3581 博磊 zenvoce.com',
    '3583 辛耘 scientech.com.tw',
    '3587 閎康 ma-tek.com',
    '3588 通嘉 leadtrend.com.tw',
    '3591 艾笛森 edison-opto.com',
    '3592 瑞鼎 rad-ic.com',
    '3593 力銘 logah.com',
    '3594 磐儀 arbor-technology.com',
    '3596 智易 arcadyan.com',
    '3597 映興 avertronics.com',
    '3605 宏致 acesconn.com',
    '3607 谷崧 coxongroup.com',
    '3609 三一東林 hepgroup.net',
    '3611 鼎翰 tscprinters.com',
    '3615 安可 aimcore.com.tw',
    '3617 碩天 cyberpower.com',
    '3622 洋華 yfo.com.tw',
    '3623 富晶通 transtouch.com.tw',
    '3624 光頡 viking.com.tw',
    '3625 西勝 c-techone.com',
    '3628 盈正 ablerex.com.tw',
    '3629 地心引力 gravitytw.com',
    '3630 新鉅科 newmax.com.tw',
    '3631 晟楠 chernan.com',
    '3632 研勤 papagoinc.com',
    '3645 達邁 taimide.com.tw',
    '3646 艾恩特 ant-precision.com.tw',
    '3652 精聯 ute.com',
    '3653 健策 jentech.com.tw',
    '3661 世芯-KY alchip.com',
    '3663 鑫科 e-ttmc.com.tw',
    '3664 安瑞-KY arraynetworks.net',
    '3665 貿聯-KY bizlinktech.com',
    '3666 光耀 optivtech.com',
    '3669 圓展 aver.com',
    '3672 康聯訊 ctsystem.com',
    '3675 德微 eris.com.tw',
    '3679 新至陞 nishoku.com.tw',
    '3680 家登 gudeng.com',
    '3684 榮昌 grand-tek.com',
    '3685 元創精密 tradetools.com.tw',
    '3686 達能 danentech.com',
    '3687 歐買尬 macrowell.com.tw',
    '3689 湧德 ude-corp.com',
    '3691 碩禾 gigasolar.com.tw',
    '3693 營邦 aicipc.com',
    '3694 海華 azurewave.com',
    '3701 大眾控 ficg.com.tw',
    '3702 大聯大 wpgholdings.com',
    '3703 欣陸 continental-holdings.com',
    '3704 合勤控 zyxelgroup.com',
    '3705 永信 yungshingroup.com',
    '3706 神達 mitac.com',
    '3707 漢磊 episil.com',
    '3708 上緯投控 swancor.com',
    '3709 鑫聯大投控 trigoldholdings.com',
    '3710 連展投控 acon-holding.com',
    '3711 日月光投控 aseglobal.com',
    '3712 永崴投控 fit-holding.com',
    '3713 新晶投控 hsinjing-holding.com.tw',
    '3714 富采 ennostar.com',
    '3715 定穎投控 dynaholding.com',
    '4102 永日 yungzip.com',
    '4104 佳醫 excelsiormedical.com.tw',
    '4105 東洋 tty.com.tw',
    '4106 雃博 tw.wellell.com',
    '4107 邦特 bioteq.com.tw',
    '4108 懷特 phytohealth.com.tw',
    '4109 加捷生醫 jiajiebio.com',
    '4111 濟生 cscp.com.tw',
    '4113 聯上 kh.vvvvv.com.tw',
    '4114 健喬 synmosa.com.tw',
    '4116 明基醫 benqmedicaltech.com',
    '4119 旭富 sci-pharmtech.com.tw',
    '4120 友華 oep.com.tw',
    '4121 優盛 rossmax.com',
    '4123 晟德 centerlab.com.tw',
    '4126 太醫 pahsco.com.tw',
    '4127 天良 tlb.com.tw',
    '4128 中天 twmicrobio.com',
    '4129 聯合 tw.unitedorthopedic.com',
    '4130 健亞 genovate-bio.com',
    '4131 浩泰 neocore.com.tw',
    '4133 亞諾法 abnova.com',
    '4137 麗豐-KY chlitina.com',
    '4138 曜亞 dmttw.com.tw',
    '4139 馬光-KY mk-healthcare.com',
    '4142 國光生 adimmune.com.tw',
    '4147 中裕 tmb.com.tw',
    '4148 全宇生技-KY allcosmos.com',
    '4153 鈺緯 diva.com.tw',
    '4154 樂威科-KY raku-tec.com.tw',
    '4155 訊映 okbiotech.com',
    '4157 太景*-KY taigenbiotech.com',
    '4160 訊聯基因 gga.asia',
    '4161 聿新科 bioptik.com.tw',
    '4162 智擎 pharmaengine.com',
    '4163 鐿鈦 intai.com.tw',
    '4164 承業醫 chcg.com',
    '4167 松瑞藥 saviorlifetec.com.tw',
    '4168 醣聯 glyconex.com.tw',
    '4171 瑞基 genereach.com',
    '4173 久裕 arich.com.tw',
    '4174 浩鼎 obipharma.com',
    '4175 杏一 medfirst.com.tw',
    '4183 福永生技 epsbio.com.tw',
    '4188 安克 amcad.com.tw',
    '4190 佐登-KY jourdeness.com',
    '4192 杏國 syncorebio.com',
    '4198 欣大健康 swissray.com',
    '4205 中華食 herngyih.com.tw',
    '4207 環泰 fructose.com.tw',
    '4303 信立 hsinli.com.tw',
    '4304 勝昱 sunvic.com.tw',
    '4305 世坤 shihkuen.com',
    '4306 炎洲 ycgroup.tw',
    '4401 東隆興 tloong.com.tw',
    '4402 福大 fu-ta.com.tw',
    '4406 新昕纖 hsinsin.com.tw',
    '4413 飛寶企業 estga.com',
    '4414 如興 roohsing.com.tw',
    '4416 三圓 shanyuan.com.tw',
    '4417 金洲 king-net.com.tw',
    '4419 元勝 ensureglobal.com.tw',
    '4420 光明 kwangming.com',
    '4426 利勤 li-cheng.com.tw',
    '4430 耀億 yaoi.com.tw',
    '4432 銘旺實 haksport.com',
    '4433 興采 singtex.com',
    '4438 廣越 qve.com.tw',
    '4439 冠星-KY tstco.com.hk',
    '4440 宜新實業 yishin-textile.com.tw',
    '4442 竣邦-KY jb-group.com.tw',
    '4502 健信 rostawheels.com.tw',
    '4503 金雨 alona.com.tw',
    '4506 崇友 gfc.com.tw',
    '4510 高鋒 kafo.com.tw',
    '4513 福裕 chevaliertw.com',
    '4523 永彰 everbrite.com.tw',
    '4526 東台 tongtai.com.tw',
    '4527 方方土霖 kuenling.com.tw',
    '4528 江興鍛 ch-forging.com.tw',
    '4529 淳紳 eptech.tw',
    '4530 宏易 honyigroup.com',
    '4532 瑞智 rechi.com',
    '4533 協易機 seyi.com',
    '4534 慶騰 trinity-ptc.com',
    '4535 至興 fineblanking.com.tw',
    '4536 拓凱 topkey.com.tw',
    '4538 大詠城 wsmc.com.tw',
    '4540 全球傳動 tbimotion.com.tw',
    '4541 晟田 maicl.com',
    '4542 科嶠 asianeotech.com',
    '4543 萬在 manzai.com.tw',
    '4545 銘鈺 mapi.com.tw',
    '4549 桓達 fine-tek.com',
    '4550 長佳 changjia.com.tw',
    '4551 智伸科 global.com.tw',
    '4552 力達-KY lidaholdings.com',
    '4554 橙的 orange-electronic.com',
    '4555 氣立 chelic.com',
    '4556 旭然 filtrafine.com.tw',
    '4557 永新-KY yusin.com',
    '4558 寶緯 alformer.com.tw',
    '4560 強信-KY strongh.tw',
    '4561 健椿 kenturn.com.tw',
    '4562 穎漢 ylm.com.tw',
    '4563 百德 quaser.com',
    '4564 元翎 twmosa.com',
    '4566 時碩工業 globaltek.com.tw',
    '4568 科際精密 koge.com',
    '4569 六方科-KY sixxontech.com',
    '4571 鈞興-KY khgears.com',
    '4572 駐龍 drewloong.com.tw',
    '4576 大銀微系統 hiwinmikro.tw',
    '4577 達航科技 ofunatech.com',
    '4580 捷流閥業 valuevalves.com',
    '4581 光隆精密-KY wkgroup.com',
    '4583 台灣精銳 apexdyna.com',
    '4584 君帆 jufan.com.tw',
    '4588 玖鼎電力 archmeter.com',
    '4609 唐鋒 airlux.com.tw',
    '4702 中美實 aicgroup.com.tw',
    '4706 大恭 tkc.com.tw',
    '4707 磐亞 pacc.com.tw',
    '4711 永純 yongshunchemical.com',
    '4712 福格創新 nantsan.com.tw',
    '4714 永捷 u-best-inno.com',
    '4716 大立 daily-polymer.com',
    '4720 德淵 texyear.com',
    '4721 美琪瑪 mechema.com',
    '4722 國精化 qualipoly.com.tw',
    '4726 永昕 mycenax.com.tw',
    '4728 雙美 sunmaxbiotech.com',
    '4729 熒茂 mildex.com.tw',
    '4735 豪展 avita.com.tw',
    '4736 泰博 taidoc.com.tw',
    '4737 華廣 bionime.com',
    '4739 康普 coremaxcorp.com',
    '4741 泓瀚 jetbest.com',
    '4743 合一 onenessbio.com',
    '4744 皇將 cvctechnologies.com.tw',
    '4745 合富-KY cowealthholding.com',
    '4746 台耀 formosalab.com',
    '4747 強生製藥 jcpjohnson.com.tw',
    '4754 國碳科 incatech.com.tw',
    '4755 三福化 sfchem.com.tw',
    '4760 勤凱科技 ampletec.com.tw',
    '4763 材料-KY acetek.com.cn',
    '4764 雙鍵 dbc.com.tw',
    '4766 南寶 nanpao.com.tw',
    '4767 誠泰科技 chetay.com.tw',
    '4768 晶呈科技 ingenteccorp.com',
    '4770 上品 alliedsupreme.com',
    '4771 望隼 vizionfocus.com',
    '4804 大略-KY dalue.co',
    '4806 桂田文創 shec.com.tw',
    '4807 日成-KY regaljewelrygroup.com',
    '4903 聯光通 ufoc.com.tw',
    '4904 遠傳 fareastone.com.tw',
    '4905 台聯電 tainet.com.tw',
    '4906 正文 gemteks.com',
    '4907 富宇 fuyu-property.com.tw',
    '4908 前鼎 apacoe.com.tw',
    '4909 新復興 nee.com.tw',
    '4911 德英 geherbs.com.tw',
    '4912 聯德控股-KY lemtech.com',
    '4915 致伸 primax.com.tw',
    '4916 事欣科 parpro.com',
    '4919 新唐 nuvoton.com',
    '4923 力士 force-mos.com',
    '4924 欣厚-KY hsihou.com',
    '4927 泰鼎-KY apex-intl.com.tw',
    '4930 燦星網 starcomgistic.com',
    '4931 新盛力 stl-tech.com',
    '4933 友輝 ubright.com.tw',
    '4934 太極 http',
    '4935 茂林-KY glthome.com.tw',
    '4938 和碩 pegatroncorp.com',
    '4939 亞電 aemg.com.tw',
    '4942 嘉彰 chiachang.com',
    '4943 康控-KY concraft.com.tw',
    '4945 陞達科技 sentelic.com',
    '4946 辣椒 wasabii.com.tw',
    '4950 牧東 muttoinc.com',
    '4951 精拓科 fintek.com.tw',
    '4952 凌通 generalplus.com',
    '4953 緯軟 wits.com',
    '4956 光鋐 epileds.com.tw',
    '4958 臻鼎-KY zdtco.com',
    '4960 誠美材 cmmt.com.tw',
    '4961 天鈺 fitipower.com',
    '4966 譜瑞-KY paradetech.com',
    '4967 十銓 teamgroupinc.com',
    '4968 立積 richwave.com.tw',
    '4971 IET-KY intelliepi.com.tw',
    '4972 湯石照明 tonslight.com',
    '4973 廣穎電通 silicon-power.com',
    '4974 亞泰 atii.com.tw',
    '4976 佳凌 calin.com.tw',
    '4977 眾達-KY pcltech.com',
    '4979 華星光 luxnetcorp.com.tw',
    '4987 科誠 godexintl.com',
    '4989 榮科 lcyt.com.tw',
    '4991 環宇-KY gcsincorp.com',
    '4994 傳奇 x-legend.tw',
    '4995 晶達 litemax.com.tw',
    '4999 鑫禾 sinher.com.tw',
    '5007 三星 sanshing.com.tw',
    '5009 榮剛 gmtc.com.tw',
    '5011 久陽 ofco.com.tw',
    '5013 強新 newbest.com.tw',
    '5014 建錩 chainchon.com',
    '5015 華祺 rodex.com.tw',
    '5016 松和 songho.com',
    '5201 凱衛 kway.com.tw',
    '5202 力新 newsoft.com.tw',
    '5203 訊連 cyberlink.com',
    '5205 中茂 twget.com.tw',
    '5206 坤悅 kunyue.com.tw',
    '5209 新鼎 asi.ctci.com',
    '5210 寶碩 apex.com.tw',
    '5211 蒙恬 penpower.com.tw',
    '5212 凌網 hyweb.com.tw',
    '5213 亞昕 yeashin.com.tw',
    '5215 科嘉-KY koja.com.tw',
    '5220 萬達光電 higgstec.com.tw',
    '5222 全訊 transcominc.com.tw',
    '5223 安力-KY anli-group.com',
    '5225 東科-KY eastech.com',
    '5227 立凱-KY aleees.com',
    '5228 鈺鎧 maxecho.com.tw',
    '5230 雷笛克光學 ledlink-optics.com',
    '5234 達興材料 daxinmat.com',
    '5236 凌陽創新 sunplusit.com',
    '5243 乙盛-KY eson.com.cn',
    '5244 弘凱 brightek.com',
    '5245 智晶 wisechip.com.tw',
    '5251 天鉞電 secufirst.com.tw',
    '5258 虹堡 castlestech.com',
    '5263 智崴 brogent.com',
    '5269 祥碩 asmedia.com.tw',
    '5272 笙科 amiccom.com.tw',
    '5274 信驊 aspeedtech.com',
    '5276 達輝-KY da-hui.com.tw',
    '5278 尚凡 i-part.com.tw',
    '5283 禾聯碩 heran.com.tw',
    '5284 jpp-KY jppholding.com',
    '5285 界霖 jihlin.com.tw',
    '5287 數字 addcn.com',
    '5288 豐祥-KY eurocharm.com.tw',
    '5289 宜鼎 innodisk.com',
    '5291 邑昇 eiso.com.tw',
    '5292 華懋 dtech-group.com',
    '5299 杰力 excelliancemos.com',
    '5301 寶得利 cjwtaiwan.com',
    '5302 太欣 stk.com.tw',
    '5306 桂盟 kmc-international.com',
    '5309 系統電 sysgration.com',
    '5310 天剛 cgs.com.tw',
    '5312 寶島科 formosa-optical.com.tw',
    '5314 世紀 myson.com.tw',
    '5315 光聯 urt.com.tw',
    '5321 美而快 unitedrecommend.com',
    '5324 士開 sldc.com.tw',
    '5328 華容 hjc.com.tw',
    '5340 建榮 baotek.com.tw',
    '5344 立衛 vate.com.tw',
    '5345 天揚 teamyoung.com',
    '5347 世界 vis.com.tw',
    '5348 正能量智能 transystem.com.tw',
    '5351 鈺創 etron.com',
    '5353 台林 tailyn.com.tw',
    '5355 佳總 gia-tzoong.com.tw',
    '5356 協益 sirtec.com.tw',
    '5364 力麗店 lealeahotel.com',
    '5371 中光電 coretronic.com',
    '5381 合正 uniplus.com.tw',
    '5383 金利 kenly.com.tw',
    '5386 青雲 albatron.com.tw',
    '5388 中磊 sercomm.com',
    '5392 能率 avy.com.tw',
    '5398 慕康生醫 mediera.com.tw',
    '5403 中菲 ddsc.com.tw',
    '5410 國眾 leosys.com',
    '5425 台半 ts.com.tw',
    '5426 振發 chengfwa.com.tw',
    '5432 新門 solomon-es.com.tw',
    '5434 崇越 topco-global.com',
    '5438 東友 tecoimage.com.tw',
    '5439 高技 fht.com.tw',
    '5443 均豪 gpmcorp.com.tw',
    '5450 南良 namliong-global.com',
    '5452 佶優 unicgroup.com',
    '5455 昇益 signality.com.tw',
    '5457 宣德 speedtech.com.tw',
    '5460 同協 mustang-group.com',
    '5464 霖宏 lhtech.com.tw',
    '5465 富驊 lfe-ww.com',
    '5468 凱鈺 tmtech.com.tw',
    '5469 瀚宇博 tw.hannstarboard.com',
    '5471 松翰 sonix.com.tw',
    '5474 聰泰 yuan.com.tw',
    '5475 德宏 glotechgf.com',
    '5478 智冠 soft-world.com',
    '5481 新華 sinotact.com',
    '5483 中美晶 saswafer.com',
    '5484 慧友 everfocus.com.tw',
    '5487 通泰 tontek.com.tw',
    '5488 松普 sunfpu.com.tw',
    '5489 彩富 dynacolor.com.tw',
    '5490 同亨 xac.com.tw',
    '5493 三聯 sanlien.com',
    '5498 凱崴 key-ware.com.tw',
    '5508 永信建 ys-construction.com.tw',
    '5511 德昌 tccon.com.tw',
    '5512 力麒 rhd.com.tw',
    '5514 三豐 sunfon.com.tw',
    '5515 建國 ckgroup.com.tw',
    '5516 雙喜 sunsea.com.tw',
    '5519 隆大 longda.com.tw',
    '5520 力泰 lihtai.com',
    '5521 工信 kseco.com.tw',
    '5522 遠雄 farglory-land.com.tw',
    '5523 豐謙 fong-chien.com.tw',
    '5525 順天 sweeten.com.tw',
    '5529 鉅陞 mega-international.com.tw',
    '5530 龍巖 lyls.com.tw',
    '5531 鄉林 shininggroup.com',
    '5533 皇鼎 founding.com.tw',
    '5534 長虹 chonghong.com.tw',
    '5536 聖暉* acter.com.tw',
    '5538 東明-KY tonggroup.com.tw',
    '5543 桓鼎-KY buima.com.tw',
    '5546 永固-KY yg1999.com',
    '5548 安倉 ancang.com',
    '5601 台聯櫃 tactc.com.tw',
    '5603 陸海 sea-land.com.tw',
    '5604 中連 cltc.com.tw',
    '5607 遠雄港 ftz.com.tw',
    '5608 四維航 swnav.com.tw',
    '5609 中菲行 dimerco.com',
    '5701 劍湖山 janfusun.com.tw',
    '5703 亞都麗緻 taipei.landishotelsresorts.com',
    '5704 老爺知 hotelroyal.com.tw',
    '5706 鳳凰 travel.com.tw',
    '5864 致和證 wintan.com.tw',
    '5871 中租-KY chaileaseholding.com',
    '5876 上海商銀 scsb.com.tw',
    '5878 台名 tabc.com.tw',
    '5880 合庫金 tcfhc.com.tw',
    '5902 德記 tait.com.tw',
    '5903 全家 family.com.tw',
    '5904 寶雅 poya.com.tw',
    '5905 南仁湖 nanrenhu.com.tw',
    '5906 台南-KY tainancayman.com',
    '5907 大洋-KY grandocean.com.tw',
    '6005 群益證 capital.com.tw',
    '6015 宏遠證 honsec.com.tw',
    '6016 康和證 concords.com.tw',
    '6020 大展證 tachan.com.tw',
    '6021 美好證 goodfinance.com',
    '6023 元大期貨 yuantafutures.com.tw',
    '6024 群益期 capitalfutures.com.tw',
    '6026 福邦證 gfortune.com.tw',
    '6101 寬魚國際 kwansinternational.wixsite.com',
    '6103 合邦 avid.com.tw',
    '6104 創惟 genesyslogic.com',
    '6108 競國 apcb.com.tw',
    '6109 亞元 atechoem.com',
    '6111 大宇資 group.softstar.com.tw',
    '6112 邁達特 metaage.com.tw',
    '6113 亞矽 asec.com.tw',
    '6114 久威 juic.com.tw',
    '6115 鎰勝 isheng.com.tw',
    '6116 彩晶 hannstar.com',
    '6117 迎廣 in-win.com',
    '6118 建達 xander.com.tw',
    '6120 達運 darwinprecisions.com',
    '6121 新普 simplo.com.tw',
    '6122 擎邦 kpec.com.tw',
    '6123 上奇 grandtech.com',
    '6124 業強 cht.yctc.com.tw',
    '6125 廣運 kenmec.com',
    '6126 信音 singatron.com.tw',
    '6127 九豪 leatec.com',
    '6128 上福 gpi.com.tw',
    '6129 普誠 princeton.com.tw',
    '6130 上亞科技 singbao.com',
    '6133 金橋 gbe.com.tw',
    '6134 萬旭 wanshih.com.tw',
    '6136 富爾特 fullerton.com.tw',
    '6138 茂達 anpec.com.tw',
    '6139 亞翔 lkeng.com.tw',
    '6140 訊達電腦 dimension.com.tw',
    '6141 柏承 plotech.com',
    '6142 友勁 cameo.com.tw',
    '6143 振曜 netronixinc.com',
    '6144 得利影 deltamac.com.tw',
    '6146 耕興 sporton.com.tw',
    '6147 頎邦 chipbond.com.tw',
    '6148 驊宏資 azion.com.tw',
    '6150 撼訊 tul.com.tw',
    '6151 晉倫 ginar.com.tw',
    '6152 百一 pesi.com.tw',
    '6153 嘉聯益 careergroups.com',
    '6154 順發 sunfar.com.tw',
    '6155 鈞寶 kingcore.com.tw',
    '6156 松上 song-shang.com.tw',
    '6158 禾昌 p-two.com',
    '6160 欣技 cipherlab.com.tw',
    '6161 捷波 jetway.com.tw',
    '6163 華電網 hwacom.com',
    '6164 華興 ledtech.com',
    '6165 浪凡 langinc.com.tw',
    '6166 凌華 adlinktech.com',
    '6167 久正 powertip.com.tw',
    '6168 宏齊 harvatek.com',
    '6169 昱泉 interserv.com.tw',
    '6170 統振 welldone.com.tw',
    '6171 大城地產 aries-net.com.tw',
    '6173 信昌電 pdc.com.tw',
    '6174 安碁 aker.com.tw',
    '6175 立敦 liton.com.tw',
    '6176 瑞儀 radiant.com.tw',
    '6177 達麗 da-li.com.tw',
    '6179 亞通 ateenergy.com.tw',
    '6180 橘子 gamania.com',
    '6182 合晶 waferworks.com',
    '6183 關貿 trade-van.com',
    '6184 大豐電 dafeng.tv',
    '6185 幃翔 plastron.com.tw',
    '6186 新潤 shinruenn.com',
    '6187 萬潤 allring-tech.com.tw',
    '6188 廣明 qsitw.com',
    '6189 豐藝 promate.com.tw',
    '6190 萬泰科 wontex.com',
    '6191 精成科 gbm.com.tw',
    '6192 巨路 elumax.com',
    '6194 育富 yufo.com.tw',
    '6195 詩肯 topshine.tw',
    '6196 帆宣 micb2b.com',
    '6197 佳必琪 jpcco.com',
    '6198 瑞築 richcircle.com.tw',
    '6199 天品 datafab.com',
    '6201 亞弘電 yahorng.com',
    '6202 盛群 holtek.com.tw',
    '6203 海韻電 seasonic.com',
    '6204 艾華 taiwanalpha.com',
    '6205 詮欣 coxoc.com.tw',
    '6206 飛捷 flytech.com',
    '6207 雷科 lasertek.com.tw',
    '6208 日揚 htcvacuum.com',
    '6209 今國光 kinko-optical.com',
    '6210 慶生 kintech.com.tw',
    '6212 理銘 met.com.tw',
    '6213 聯茂 iteq.com.tw',
    '6214 精誠 systex.com',
    '6215 和椿 aurotek.com.tw',
    '6216 居易 draytek.com',
    '6217 中探針 ccpcontactprobes.com',
    '6218 豪勉 hauman.com.tw',
    '6219 富旺 fwgroup.com.tw',
    '6220 岳豐 cables.com.tw',
    '6221 晉泰 genesis.com.tw',
    '6222 上揚 sotac.com.tw',
    '6223 旺矽 mpi.com.tw',
    '6224 聚鼎 pttc.com.tw',
    '6225 天瀚 aiptek.tw',
    '6226 光鼎 para.com.tw',
    '6227 茂綸 macnica.com',
    '6228 全譜 scanace.com',
    '6229 研通 vtac.com.tw',
    '6230 尼得科超眾 ccic.com.tw',
    '6231 系微 insyde.com',
    '6233 旺玖 prolific.com.tw',
    '6234 高僑 teraauto.com.tw',
    '6235 華孚 waffer.com.tw',
    '6236 中湛 neithcorp.com',
    '6237 驊訊 cmedia.com.tw',
    '6239 力成 pti.com.tw',
    '6240 松崗 sunggang.com.tw',
    '6241 易通展 amigo.com.tw',
    '6242 立康 likangbio.com',
    '6243 迅杰 ene.com.tw',
    '6244 茂迪 motech.com.tw',
    '6245 立端 lannerinc.com',
    '6246 臺龍 tai-lon.com',
    '6248 沛波 tmpco.com.tw',
    '6257 矽格 sigurd.com.tw',
    '6259 百徽 bullwill.com.tw',
    '6261 久元 ytec.com.tw',
    '6263 普萊德 planet.com.tw',
    '6264 富裔 kingland.com.tw',
    '6265 方方土昶 kc-uppertech.com',
    '6266 泰詠 topunion.com.tw',
    '6269 台郡 flexium.com.tw',
    '6270 倍微 pct.com.tw',
    '6271 同欣電 theil.com',
    '6274 台燿 tuc.com.tw',
    '6275 元山 ystech.com.tw',
    '6276 安鈦克 antec.com',
    '6277 宏正 aten.com',
    '6278 台表科 tsmt.com.tw',
    '6279 胡連 hulane.com',
    '6281 全國電 elifemall.com.tw',
    '6282 康舒 acbel.com',
    '6283 淳安 soe-ele.com',
    '6284 佳邦 inpaq.com.tw',
    '6285 啟碁 wnc.com.tw',
    '6287 元隆 ampi.com.tw',
    '6288 聯嘉 eoi.com.tw',
    '6290 良維 longwell.com',
    '6291 沛亨 analog.com.tw',
    '6292 迅德 axis.com.tw',
    '6294 智基 tekom.com.tw',
    '6404 通訊-KY ccmg.mobi',
    '6405 悅城 onano-nm.com',
    '6409 旭隼 voltronicpower.com',
    '6411 晶焱 amazingic.com',
    '6412 群電 chiconypower.com.tw',
    '6414 樺漢 ennoconn.com',
    '6415 矽力*-KY silergy.com',
    '6416 瑞祺電通 cas-well.com',
    '6417 韋僑 sag.com.tw',
    '6418 詠昇 yselec.com.tw',
    '6419 京晨科 nuuo.com',
    '6425 易發 efctw.com',
    '6426 統新 nextapogee.com.tw',
    '6431 光麗-KY kl-holdings.com',
    '6432 今展科 arlitech.com.tw',
    '6435 大中 sinopowersemi.com',
    '6438 迅得 saa-symtek.com',
    '6441 廣錠 ibasegaming.com',
    '6442 光聖 ezconn.com',
    '6443 元晶 tsecpv.com',
    '6446 藥華藥 pharmaessentia.com',
    '6449 鈺邦 apaq.com.tw',
    '6451 訊芯-KY shunsintech.com',
    '6456 GIS-KY gis-touch.com',
    '6457 紘康 hycontek.com',
    '6461 益得 intechbiopharm.com',
    '6462 神盾 egistec.com',
    '6464 台數科 topmso.com.tw',
    '6465 威潤 atrack.com.tw',
    '6469 大樹 greattree.com.tw',
    '6470 宇智 u-media.com.tw',
    '6472 保瑞 bora-corp.com',
    '6477 安集 anjitek.com',
    '6482 弘煜科 fy.com.tw',
    '6485 點序 asolid-tek.com',
    '6486 互動 idtech.com.tw',
    '6488 環球晶 sas-globalwafers.com',
    '6491 晶碩 pegavision.com',
    '6492 生華科 senhwabio.com',
    '6494 九齊 nyquest.com.tw',
    '6496 科懋 excelsiorgroup.com.tw',
    '6499 益安 medeonbiodesign.com',
    '6504 南六 e-nonwoven.com.tw',
    '6505 台塑化 fpcc.com.tw',
    '6506 雙邦 shuang-bang.com',
    '6508 惠光 huikwang.com',
    '6509 聚和 hopax.com',
    '6510 精測 chpt.com',
    '6512 啟發電 gomax-electronics.com.tw',
    '6514 芮特-KY rt-inc.com',
    '6515 穎崴 winwayglobal.com',
    '6516 勤崴國際 kingwaytek.com',
    '6517 保勝光學 baso.com.tw',
    '6523 達爾膚 drwu.com',
    '6525 捷敏-KY gemservices.com',
    '6526 達發 airoha.com',
    '6527 明達醫 crystalvue.com.tw',
    '6530 創威 axcen.com.tw',
    '6531 愛普* apmemory.com',
    '6532 瑞耘 calitech.com.tw',
    '6533 晶心科 andestech.com',
    '6534 正瀚-創 chbio.com.tw',
    '6535 順藥 lumosa.com.tw',
    '6538 倉和 bch.com.tw',
    '6541 泰福-KY tanvex.com',
    '6542 隆中 gamesparcs.com',
    '6546 正基 ampak.com.tw',
    '6547 高端疫苗 medigenvac.com',
    '6548 長科* cwtcglobal.com',
    '6550 北極星藥業-KY polarispharma.com',
    '6552 易華電 jmct.com.tw',
    '6556 勝品 topviewcorp.com',
    '6558 興能高 synst.com.tw',
    '6560 欣普羅 appropho.com',
    '6561 是方 chief.com.tw',
    '6568 宏觀 rafaelmicro.com',
    '6569 醫揚 onyx-healthcare.com',
    '6570 維田 aplex.com',
    '6573 虹揚-KY hygroup.com.tw',
    '6574 霈方 mikobeaute.com',
    '6576 逸達 foreseepharma.com',
    '6577 勁豐 promate.com',
    '6578 達邦蛋白 dabombprotein.com',
    '6579 研揚 aaeon.com',
    '6581 鋼聯 tsutw.com.tw',
    '6582 申豐 shinfoong.com.tw',
    '6584 南俊國際 repon.com.tw',
    '6585 鼎基 dingzing.com',
    '6588 東典 east-tender.com',
    '6589 台康生技 eirgenix.com',
    '6590 普鴻 provision.com.tw',
    '6591 動力-KY sun-max.com.tw',
    '6592 和潤企業 hfcfinance.com.tw',
    '6593 台灣銘板 tnp.com.tw',
    '6596 寬宏藝術 khaminc.com.tw',
    '6598 ABC-KY apbiocode.com',
    '6603 富強鑫 fcs.com.tw',
    '6605 帝寶 depoautolamp.com',
    '6606 建德工業 kentind.com',
    '6609 瀧澤科 takisawa.com.tw',
    '6612 奈米醫材 icaresmedicus.com',
    '6613 朋億* novatech.com.tw',
    '6615 慧智 sofivagenomics.com.tw',
    '6616 特昇-KY techcential-international.com',
    '6624 萬年清 ever-clear.com.tw',
    '6625 必應 bin-live.com',
    '6629 泰金-KY thai-kin.com',
    '6640 均華 gmmcorp.com.tw',
    '6641 基士德-KY gsd.net.tw',
    '6642 富致 fuzetec.com',
    '6643 M31 m31tech.com',
    '6645 金萬林-創 kimforest.com',
    '6649 台生材 twbm.com.tw',
    '6651 全宇昕 cystekec.com',
    '6654 天正國際 tz-int-ltd.com',
    '6655 科定 twkd.com',
    '6657 華安 energenesis-biomedical.com',
    '6658 聯策 synpower.com.tw',
    '6661 威健生技 http：',
    '6662 樂斯科 biolasco.com.tw',
    '6664 群翊 gpline.com.tw',
    '6666 羅麗芬-KY luolihfen.com',
    '6667 信紘科 trusval.com.tw',
    '6668 中揚光 jmo-corp.com',
    '6669 緯穎 wiwynn.com',
    '6670 復盛應用 fusheng-precision.com',
    '6671 三能-KY sannenggroup.com',
    '6672 騰輝電子-KY ventec-group.com',
    '6674 鋐寶科技 icbn.com.tw',
    '6679 鈺太 zilltek.com',
    '6680 鑫創電子 sintrones.com',
    '6683 雍智科技 ksmt.com.tw',
    '6684 安格 algoltek.com.tw',
    '6689 伊雲谷 ecloudvalley.com',
    '6690 安碁資訊 acercsi.com',
    '6691 洋基工程 yankey.com.tw',
    '6692 進能服 acmepointes.com',
    '6693 廣閎科 inergy.com.tw',
    '6695 芯鼎 icatchtek.com',
    '6697 東捷資訊 itts.com.tw',
    '6698 旭暉應材 fine-mat.com',
    '6703 軒郁 shinybrands.com',
    '6706 惠特 fittech.com.tw',
    '6708 天擎 mars-semi.com.tw',
    '6712 長聖 ever-supreme.com.tw',
    '6715 嘉基 lintestech.com',
    '6716 應廣 padauk.com.tw',
    '6719 力智 upi-semi.com',
    '6721 信實 sincere-group.com.tw',
    '6727 亞泰金屬 asiametalinc.com',
    '6728 上洋 upyoung.com.tw',
    '6732 昇佳電子 sensortek.com.tw',
    '6733 博晟生醫 biogend.com.tw',
    '6735 美達科技 amidatec.com',
    '6741 91APP*-KY 91app.com',
    '6742 澤米 vactronics.com.tw',
    '6743 安普新 ampacscorp.com',
    '6747 亨泰光 brightenoptix.com.tw',
    '6751 智聯服務 acer-ast.com',
    '6752 叡揚 gss.com.tw',
    '6753 龍德造船 lungteh.com',
    '6754 匯僑設計 richhonour.com',
    '6756 威鋒電子 via-labs.com',
    '6757 台灣虎航-創 tigerairtw.com',
    '6761 穩得 wendell.com.tw',
    '6762 達亞 deltaasia.com.tw',
    '6763 綠界科技 ecpay.com.tw',
    '6767 台微醫 wiltrom.com.tw',
    '6768 志強-KY sportsgear.com.tw',
    '6770 力積電 powerchip.com',
    '6776 展碁國際 weblink.com.tw',
    '6781 AES-KY advancedenergysolution.com.tw',
    '6782 視陽 viscovision.com.tw',
    '6785 昱展新藥 alarpharm.com',
    '6788 華景電 brillian.com.tw',
    '6789 采鈺 viseratech.com',
    '6790 永豐實 yfycpg.com',
    '6791 虎門科技 cadmen.com',
    '6792 詠業 unictron.com',
    '6796 晉弘 miis.com.tw',
    '6799 來頡 m3tekic.com',
    '6803 崑鼎 ecove.com',
    '6804 明係 axman.com.tw',
    '6805 富世達 fositek.com',
    '6806 森崴能源 shinfox.com.tw',
    '6807 峰源-KY fy-grp.com',
    '6811 宏碁資訊 aceraeb.com',
    '6821 聯寶 linkcom.com.tw',
    '6823 濾能 greenfiltec.com',
    '6829 千附精密 cfprec.com.tw',
    '6830 汎銓 msscorps.com',
    '6834 天二科技 everohms.com',
    '6835 圓裕 cmi.com.tw',
    '6840 東研信超 btl.com.tw',
    '6841 長佳智能 everfortuneai.com.tw',
    '6843 進典 jdv.com.tw',
    '6844 諾貝兒 norbelbaby.com.tw',
    '6846 綠茵 greenynbio.com',
    '6854 錼創科技-KY創 playnitride.com',
    '6855 數泓科 eclatorq.com',
    '6856 鑫傳 st-media.com.tw',
    '6859 伯特光 best-opto.com',
    '6861 睿生光電 innocare-x.com',
    '6863 永道-KY arizontw.com',
    '6865 偉康科技 webcomm.com.tw',
    '6869 雲豹能源-創 jv-holding.com',
    '6870 騰雲 turn2cloud.com',
    '6873 泓德能源-創 hdrenewables.com',
    '6874 倍力 mpinfo.com.tw',
    '6875 國邑* pharmosa.com.tw',
    '6877 鏵友益 hyetech.com.tw',
    '6894 衛司特 recotech.com.tw',
    '6895 宏碩系統 wavepower.com.tw',
    '6901 鑽石投資 diamondbiofund.com',
    '6902 走著瞧-創 gogolook.com',
    '6904 伯鑫 proxene.com.tw',
    '6906 現觀科 ghtinc.com',
    '6916 華凌 winstar.com.tw',
    '6922 宸曜 neousys-tech.com',
    '6929 佑全 yourchance.com.tw',
    '6933 AMAX-KY amax.tw',
    '6937 天虹 skytech.com.tw',
    '6949 沛爾生醫-創 pellbmt.com',
    '7402 邑錡 brinno.com',
    '7556 意德士科技 yeedex.com.tw',
    '8011 台通 ttcc.com.tw',
    '8016 矽創 sitronix.com.tw',
    '8021 尖點 topoint.tw',
    '8024 佑華 ealpha.com.tw',
    '8027 鈦昇 enr.com.tw',
    '8028 昇陽半導體 psi.com.tw',
    '8032 光菱 koryo.com.tw',
    '8033 雷虎 thundertiger.com',
    '8034 榮群 opnet.com.tw',
    '8038 長園科 caec.com.tw',
    '8039 台虹 taiflex.com.tw',
    '8040 九暘 icplus.com.tw',
    '8042 金山電 chinsan.com',
    '8043 蜜望實 threehhh.com.tw',
    '8044 網家 pchome.com.tw',
    '8046 南電 nanyapcb.com.tw',
    '8047 星雲 gccworld.com',
    '8048 德勝 rubytech.com.tw',
    '8049 晶采 ampire.com.tw',
    '8050 廣積 ibase.com.tw',
    '8054 安國 alcormicro.com',
    '8059 凱碩 castlenet.com.tw',
    '8064 東捷 contrel.com.tw',
    '8066 來思達 lifestyle-global.com',
    '8067 志旭 gish.com.tw',
    '8068 全達 chander.com.tw',
    '8069 元太 eink.com',
    '8070 長華* cwei.com.tw',
    '8071 能率網通 fstdisc.com.tw',
    '8072 陞泰 avtechgroup.com.tw',
    '8074 鉅橡 aurona.com.tw',
    '8076 伍豐 fecpos.com',
    '8077 洛碁 greenworldhotels.com',
    '8080 印鉐 ost.com.tw',
    '8081 致新 gmt.com.tw',
    '8083 瑞穎 prohawk.com.tw',
    '8084 巨虹 chiphope.com',
    '8085 福華 fwd.com.tw',
    '8086 宏捷科 awsc.com.tw',
    '8087 華鎂鑫 leadsun-green.com',
    '8088 品安 panram.com.tw',
    '8089 康全電訊 comtrend.com',
    '8091 翔名 feedback.com.tw',
    '8092 建暐 chienwei.com',
    '8093 保銳 enermax.com.tw',
    '8096 擎亞 coasiaelec.com',
    '8097 常珵 atwtechnology.com',
    '8099 大世科 etatung.com',
    '8101 華冠 arimacomm.com.tw',
    '8103 瀚荃 cvilux-group.com',
    '8104 錸寶 ritdisplay.com',
    '8105 凌巨 giantplus.com',
    '8107 大億金茂 kenmos.com',
    '8109 博大 pduke.com',
    '8110 華東 walton.com.tw',
    '8111 立碁 ligitek.com',
    '8112 至上 supreme.com.tw',
    '8114 振樺電 posiflex.com.tw',
    '8121 越峰 acme-ferrite.com.tw',
    '8131 福懋科 fatc.com.tw',
    '8147 正淩 nextrongroup.com',
    '8150 南茂 chipmos.com',
    '8155 博智 accl.com.tw',
    '8162 微矽電子-創 msec.com.tw',
    '8163 達方 darfon.com.tw',
    '8171 天宇 http：',
    '8176 智捷 zcom.com.tw',
    '8182 加高 hele.com.tw',
    '8183 精星 psaitc.com',
    '8201 無敵 besta.com.tw',
    '8210 勤誠 chenbro.com',
    '8213 志超 tpt-pcb.com.tw',
    '8215 明基材 benqmaterials.com',
    '8222 寶一 aerowin.com',
    '8227 巨有科技 pgc.com.tw',
    '8234 新漢 nexcom.com.tw',
    '8240 華宏 wahhong.com',
    '8249 菱光 csi-sensor.com.tw',
    '8255 朋程 actron.com.tw',
    '8261 富鼎 a-power.com.tw',
    '8271 宇瞻 apacer.com',
    '8277 商丞 unifosa.com.tw',
    '8279 生展 syngen.com.tw',
    '8284 三竹 mitake.com.tw',
    '8289 泰藝 taitien.com.tw',
    '8291 尚茂 shinemore.com.tw',
    '8299 群聯 phison.com',
    '8341 日友 sunnyfriend.com.tw',
    '8342 益張 ijang.com.tw',
    '8349 恒耀國際 qst.com.tw',
    '8354 冠好 koanhao.com',
    '8358 金居 co-tech.com',
    '8367 建新國際 chienshing.com.tw',
    '8374 羅昇 acepillar.com.tw',
    '8383 千附 chenfull.com.tw',
    '8390 金益鼎 jyd.com.tw',
    '8401 白紗科 bsbs.com.tw',
    '8403 盛弘 share-hope.com',
    '8404 百和興業-KY kypaiho.com',
    '8409 商之器 ebmtech.com',
    '8410 森田 sentien.com.tw',
    '8411 福貞-KY kingcanholdings.com',
    '8415 大國鋼 brighton-best.com.tw',
    '8416 實威 swtc.com',
    '8420 明揚 launchtech.com.tw',
    '8421 旭源 xuyuanpack.com',
    '8422 可寧衛 cleanaway.tw',
    '8423 保綠-KY pgr.com.tw',
    '8424 惠普 wellpool.com.tw',
    '8426 紅木-KY redwoodgroup.co',
    '8429 金麗-KY jinligroup.com.tw',
    '8431 匯鑽科 thesuperiorplating.com',
    '8432 東生華 tshbiopharm.com',
    '8433 弘帆 bonfame.com',
    '8435 鉅邁 zimmite.com',
    '8436 大江 tci-bio.com',
    '8437 大地-KY edudadi.com',
    '8438 昶昕 persee.com.tw',
    '8440 綠電 eer.com.tw',
    '8442 威宏-KY ww-holding.com.tw',
    '8443 阿瘦 asogroup.com.tw',
    '8444 綠河-KY greenriverholding.com',
    '8446 華研 him.com.tw',
    '8450 霹靂 pili.com.tw',
    '8454 富邦媒 corp.momo.com.tw',
    '8455 大拓-KY daito-me.com.tw',
    '8462 柏文 powerwindhealth.com.tw',
    '8463 潤泰材 rtm.com.tw',
    '8464 億豐 nienmade.com.tw',
    '8466 美吉吉-KY mjig.com',
    '8467 波力-KY bonnyworldwide.com',
    '8472 夠麻吉 gomaji.com',
    '8473 山林水 mfw.com.tw',
    '8476 台境 tesc.com.tw',
    '8477 創業家 kuobrothers.com',
    '8478 東哥遊艇 oceanalexander.com',
    '8481 政伸 transart.com.tw',
    '8482 商億-KY shaneglobal.com.tw',
    '8487 愛爾達-創 elta.com.tw',
    '8488 吉源-KY jypkg.com',
    '8489 三貝德 samebest.com.tw',
    '8499 鼎炫-KY topbright.taiwanscale.com',
    '8905 裕國 eaglegroup.com.tw',
    '8906 花王 forward-net.com.tw',
    '8908 欣雄 shng.com.tw',
    '8916 光隆 klf-group.com',
    '8917 欣泰 htgas.com.tw',
    '8921 沈氏 shen.com.tw',
    '8923 時報 readingtimes.com.tw',
    '8924 大田 o-ta.com.tw',
    '8926 台汽電 cogen.com.tw',
    '8927 北基 nspco.com.tw',
    '8928 鉅明 dynamic.com.tw',
    '8929 富堡 fuburg.com',
    '8930 青鋼 ckm.com.tw',
    '8931 大汽電 tycc.com.tw',
    '8932 智通 wiselink.tw',
    '8933 愛地雅 idealbike.com.tw',
    '8935 邦泰 pontex.com',
    '8936 國統 kti.com.tw',
    '8937 合騏 hercheemoto.com.tw',
    '8938 明安 adgroup.com.tw',
    '8940 新天地 newpalace.com.tw',
    '8941 關中 grandhall.com.tw',
    '8942 森鉅 xxentria-tech.com',
    '8996 高力 kaori.com.tw',
    '9802 鈺齊-KY fulgentsun.com',
    '9902 台火 tidehold.com',
    '9904 寶成 pouchen.com',
    '9905 大華 greatchina.com.tw',
    '9906 欣巴巴 hsinbaba.com.tw',
    '9907 統一實 tonyi.com.tw',
    '9908 大台北 taipeigas.com.tw',
    '9910 豐泰 fengtay.com',
    '9911 櫻花 sakura.com.tw',
    '9912 偉聯 agneovo.com',
    '9914 美利達 merida.com.tw',
    '9917 中保科 secom.com.tw',
    '9918 欣天然 shinshingas.com.tw',
    '9919 康那香 knh.com.tw',
    '9921 巨大 giantgroup-cycling.com',
    '9924 福興 fuhsing.com.tw',
    '9925 新保 sks.com.tw',
    '9926 新海 shinhaigas.com.tw',
    '9927 泰銘 tmicl.com.tw',
    '9928 中視 ctv.com.tw',
    '9929 秋雨 choiceprintgroup.com',
    '9930 中聯資源 chc.com.tw',
    '9931 欣高 hkgas.com.tw',
    '9933 中鼎 ctci.com',
    '9934 成霖 tw.globeunion.com',
    '9935 慶豐富 chingfeng.com',
    '9937 全國 npcgas.com.tw',
    '9938 百和 paiho.com',
    '9939 宏全 honchuan.com',
    '9940 信義 sinyi.com.tw',
    '9941 裕融 tac.com.tw',
    '9942 茂順 nak.com.tw',
    '9943 好樂迪 holiday.com.tw',
    '9944 新麗 shinih.com.tw',
    '9945 潤泰新 rt-develop.com.tw',
    '9946 三發地產 sanfar.com.tw',
    '9949 琉園 tittot.com',
    '9950 萬國通 eminent.com',
    '9951 皇田 macauto-group.com',
    '9955 佳龍 sdti.com.tw',
    '9958 世紀鋼 century.com.tw',
    '9960 邁達康 golfshop.com.tw',
    '9962 有益 yeouyih.com.tw'
    ]
