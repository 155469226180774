import React from 'react';
import './App.css';
import { Navigate, Outlet } from "react-router-dom";
import { Box, Stack } from '@mui/material';
import SideBar from './components/SideBar-internal';


function App() {
  return (
    <div className="App" style={{ background: "rgba(65,105,225,0.1)", overflow: "auto" }}>
      {/* <Stack direction="row"> */}
      <Box sx={{ display: 'flex' }}>
        <SideBar />
        <Outlet />
      </Box>
      {/* </Stack> */}
    </div>
  );
}

export default App;
