import React from 'react'
import { useEffect, useState } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'

import { ArrowBackIos } from '@mui/icons-material'
import Grid from '@mui/material/Unstable_Grid2'
import { Button, Box, Typography, Tabs, Tab } from '@mui/material'
import { HomeWork, Assessment, Hub, Article, Groups, RepeatOn, QueryStats } from '@mui/icons-material'
import Graph from './CompanyTab/Graph'
import CompanyData from './CompanyTab/CompanyData'
import Director from './CompanyTab/CompanyDirector'

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props
    return (
        <div
            // role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

function Company() {
    const navigate = useNavigate()
    const [value, setValue] = React.useState(0)
    const companySession = sessionStorage.getItem('companyInfoData')
    const companyInfoBySession = companySession ? JSON.parse(companySession) : null
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    return (
        <Box sx={{ height: "100vh", width: '90%', padding: "30px" }}>
            <div style={{ textAlign: "left" }}>
                <Button variant="contained" size="small" startIcon={<ArrowBackIos />} color="primary" disableElevation disableRipple sx={{ margin: "0px", width: "80px", borderRadius: "10px" }} onClick={() => navigate("../")}> 返回 </Button>
            </div>

            {/* Title */}
            <Grid xs={12} sx={{ width: '100%', display: "flex", justifyContent: "flex-start", padding: "20px 0px" }}>
                <Typography
                    sx={{ flex: '1 1 auto', textAlign: "left", fontWeight: "bold", fontSize: "30px" }}
                    variant="h5"
                    id="tableTitle"
                    component="div"
                >
                    {companyInfoBySession ? companyInfoBySession.companyName : '公司信息'}
                </Typography>
            </Grid>

            {/* Tabs */}
            <Grid xs={12} sx={{ width: '100%', display: "flex", justifyContent: "flex-start" }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
                    {/* <Tab sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '0px', paddingRight: '20px' }}
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Article sx={{ marginRight: 1 }} />
                                <div>最新消息</div>
                            </div>
                        }
                        {...a11yProps(0)}
                        onClick={() => navigate('/company')}
                    /> */}

                    <Tab sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '0px', paddingRight: '20px' }}
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <HomeWork sx={{ marginRight: 1 }} />
                                <div>公司基本資料</div>
                            </div>
                        }
                        {...a11yProps(0)}
                        onClick={() => navigate('/company')}
                    />

                    <Tab sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '0px', paddingRight: '20px' }}
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Assessment sx={{ marginRight: 1 }} />
                                <div>財務報表</div>
                            </div>
                        }
                        {...a11yProps(1)}
                        onClick={() => navigate('/company/income')}
                    />

                    <Tab sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '0px', paddingRight: '20px' }}
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Groups sx={{ marginRight: 1 }} />
                                <div>董事成員</div>
                            </div>
                        }
                        {...a11yProps(2)}
                        onClick={() => navigate('/company')}
                    />
                </Tabs>
            </Grid>

            {/* <CustomTabPanel value={value} index={0}>
                <News />
            </CustomTabPanel> */}
            <CustomTabPanel value={value} index={0}>
                <CompanyData />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Outlet />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <Director />
            </CustomTabPanel>
        </Box>
    )
}

export default Company
