import React, { useState } from 'react'
export default function Comparison() {
    const URLindustry = JSON.parse(sessionStorage.getItem("companyInfoData") || '{}')?.industryClassification
    // 這裡應該是 Looker 報表中定義的正確篩選器名稱
    let filterName = "ds10.industry" // 假設 Looker 中的篩選器名稱是 "industry"
    // 這裡是您希望報表按照此參數進行篩選的值
    let filterValue = URLindustry // 這是您希望篩選的行業名稱
    // 創建一個包含篩選器名稱和值的物件
    let params = {
        [filterName]: filterValue
        //"df13": "include0IN光電業"
    }

    let paramsAsString = JSON.stringify(params)
    let encodedParams = encodeURIComponent(paramsAsString)
    encodedParams = encodeURIComponent(encodedParams)
    // 換掉encoded的，要變成實際看到的
    let extractedPart = encodedParams.replace('%257B%2522ds10.industry%2522%253A%2522', '')
    const baseUrl = process.env.REACT_APP_LOOKER_URL
    const paramsAsUrl = "?params=%7B%22df12%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580"
   
    const fullUrl = `${baseUrl}${paramsAsUrl}${extractedPart}`
    return (
        <iframe
            title="bizinfo analyze"
            src={fullUrl}
            frameBorder="0"
            width='100%'
            height='800px'
        >
        </iframe>
    )
}
