import React, { useEffect, useState } from 'react'

import { Button, Box, Typography, Toolbar, Autocomplete, TextField } from '@mui/material'

// axios
import { infoGET } from '../utils/cpmpanyAxios'

// react-router
import { Navigate, Outlet, useNavigate } from 'react-router-dom'

// data
import { companyOptions } from '../shared/selectOptions'

// css
import { homeInputSX } from '../css/formStyle'
import axios from 'axios'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
type Loggedin = "init" | "login" | "logout"


// 為了防止使用者輸入 '\'，將影響到 regex
function escapeRegExp(input) {
    return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

function filterOptions(companyOptions: Array<string>, { inputValue }) {
    // 使用正則表達式進行安全匹配 
    const escapedInputValue = escapeRegExp(inputValue.trim())
    const regex = new RegExp(escapedInputValue, 'i')
    return companyOptions.filter(option => regex.test(option))
}


export default function Home() {

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [isOnChangeComplete, setIsOnChangeComplete] = useState(true)
    const [domainBySelectedOption, setDomainBySelectedOption] = useState('')
    const [isComposing, setIsComposing] = useState(false)
    const [loggedin, setLoggedin] = useState<Loggedin>("init")

    // css
    const buttonStyle = {
        borderRadius: '15px',
        fontSize: '14px',
        padding: '12px 25px',
    }
    const handleCompositionStart = () => {
        setIsComposing(true)
    }
    const handleCompositionEnd = () => {
        setIsComposing(false)
    }

    const searchForInfo = async () => {
        if (!domainBySelectedOption) {
            alert('🔴 查無此公司')
            return
        }
        await searchForInfoWrapper(domainBySelectedOption)
    }

    const searchForInfoWrapper = async (domain: string) => {
        try {
            setLoading(true)
            await infoGET(domain)
            navigate('/company')
        } catch (error) {
            console.error('API請求錯誤：', error)
        } finally {
            setLoading(false)
        }
    }

    const searchForInfo2330 = async () => {
        await searchForInfoWrapper('tsmc.com')
    }

    const searchForInfo2454 = async () => {
        await searchForInfoWrapper('mediatek.com')
    }

    const searchForInfo6112 = async () => {
        await searchForInfoWrapper('metaage.com.tw')
    }


    // 一開始下拉選單不出現
    const handleInputChange = (event, value, reason) => {
        setIsOnChangeComplete(false)
        if (reason === 'input') {
            if (value.trim() === '') {
                setOpen(false)
            } else {
                setOpen(true)
            }
        }
    }

    // 只拿domain
    const handleOptionChange = (event, value) => {
        if (value && value.includes(' ')) {
            const parts = value.split(' ')
            const domain = parts.slice(2).join(' ')
            setDomainBySelectedOption(domain)
            // 表明 onChange 已完成
            setIsOnChangeComplete(true)
        }
    }
    // enter key
    const handleEnterKeyPress = (event) => {
        if (event.key === 'Enter' && isOnChangeComplete) {
            searchForInfo()
        }
    }

    /**
     * check JWT to determine login or logout state
     */
    const auth = async () => {
        // initial state
        // valid -> login
        // expire or not exist -> logout
        try {
            const res = await fetch(`${BACKEND_URL}/api/v1/auth/refreshtoken`, {
                credentials: "include",
            })
            if (res.status === 200) {
                setLoggedin("login")
            } else {
                setLoggedin("logout")
            }
        } catch (error) {
            console.error("Error during authentication:", error)
            setLoggedin("logout")
        }
    }

    useEffect(() => {
        auth()
    }, [])


    if (loggedin == "init")
        return (<></>)     // blank page

    else if (loggedin == "logout")
        return (<Navigate to="/login" replace={true} />)  

    else
        return (
            <Box sx={{ background: "#F4F7FE", height: "100vh", width: "100vw", textAlign: "center" }}>
                <Box sx={{ height: "95%", paddingTop: "15vh" }}>
                    <img src="./epicloud.png" alt="epicloud icon" style={{ width: "200px" }}></img>

                    <Typography sx={{ width: "100%", fontSize: "30px", fontWeight: "bold", color: "#2B3674", marginBottom: "30px" }}>
                        上市櫃Bizinfo平台
                    </Typography>

                    {/* Search Bar */}
                    <Toolbar sx={{ height: '60px', justifyContent: 'center', marginBottom: '30px', marginLeft: '40px' }} variant='dense'>
                        <Autocomplete
                            options={companyOptions}
                            open={open}
                            onClose={() => setOpen(false)}
                            filterOptions={filterOptions}
                            onInputChange={handleInputChange}
                            onChange={handleOptionChange}
                            autoHighlight={true}
                            getOptionLabel={(option) => option.split(' ').slice(0, 2).join(' ')}
                            renderOption={(props, option) => (
                                <li {...props}>
                                    {option.split(' ').slice(0, 2).join(' ')} {/* 只顯示除domain部分 */}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="股票代號或簡稱"
                                    onKeyDown={handleEnterKeyPress}
                                    onCompositionStart={handleCompositionStart}
                                    onCompositionEnd={handleCompositionEnd}
                                />
                            )}
                            sx={homeInputSX}
                        />
                        {/* 根據loading狀態来禁用按钮 */}
                        <Button variant='contained' size='small' disableElevation color='primary'
                            sx={{ borderRadius: '10px', fontSize: '10px', padding: '10px 15px', margin: '0px 10px' }}
                            onClick={searchForInfo}
                            disabled={loading}
                        >
                            搜尋公司
                        </Button>
                    </Toolbar>
                    <div style={{ marginLeft: '25px' }}>
                        <Button variant='contained' disableElevation color='inherit'
                            sx={{ ...buttonStyle, mr: '0px' }}
                            onClick={searchForInfo2330}
                            disabled={loading}
                        >
                            2330 台積電
                        </Button>
                        <Button variant='contained' disableElevation color='inherit'
                            sx={{ ...buttonStyle, mr: '75px', ml: '75px' }}
                            onClick={searchForInfo2454}
                            disabled={loading}
                        >
                            2454 聯發科
                        </Button>
                        <Button variant='contained' disableElevation color='inherit'
                            sx={{ ...buttonStyle, mr: '0px' }}
                            onClick={searchForInfo6112}
                            disabled={loading}
                        >
                            6112 邁達特
                        </Button>
                    </div>
                </Box>

                <Typography sx={{ width: "100%", fontSize: "12px" }}>
                    Copyright © 2023 Epic Cloud. All Rights Reserved.
                </Typography>
            </Box>
        )
}
